export enum USER_ROLE {
    ADMIN = 'ADMIN',
    MEMBER = 'MEMBER'
}
export enum USER_TYPE {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL'
}

export enum CONTRACT_CHECKLIST_STATUS {
    COMPLIANCE = "COMPLIANCE",
    NOT_IN_COMPLIANCE = "NOT_IN_COMPLICANCE",
    SAVE_FOR_LATER = "SAVE_FOR_LATER"
}

export enum TAG_DATA_TYPES {
    TEXT = 'Text',
    NUMBER = 'Number',
    DATE = 'Date',
    CUSTOM = 'Custom'
}

export enum EXTRACTION_STATUS {
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}

export enum JOB_TYPE {
    REFRESH = "REFRESH",
    TAG_EXTRACTION = "TAG_EXTRACTION",
    SINGLE_TAG_EXTRACTION = "SINGLE_TAG_EXTRACTION",
    CHECKLIST = "CHECKLIST",
    SINGLE_CHECKLIST_ITEM = "SINGLE_CHECKLIST_ITEM",
    SUMMARY = "SUMMARY",
    REPO_TAG_EXTRACTION = "REPO_TAG_EXTRACTION",
    GENERATE_DOCUMENT = "GENERATE_DOCUMENT"
}

export enum JOB_STATUS {
    STARTED = "STARTED",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED",
    CANCELLED = "CANCELLED"
}

export enum FILE_TYPE {
    WORD = 'docx',
    PDF = 'pdf',
    EXCEL = 'xlsx',
    POWERPOINT = 'pptx'
}

export enum ONLY_OFFICE_EDITOR_TYPE {
    WORD = 'word',
    PDF = 'pdf',
    EXCEL = 'cell',
    POWERPOINT = 'slide'
}